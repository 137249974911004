<template>
  <div class="BuySubscription">
    <div class="subscribe-creator" slot="header" v-if="creator">
      <div class="avatar">
        <UserAvatar :user="creator" size="100px" border="true" />
      </div>
      <div class="info">
        <p class="name" strong>{{ creator.name }}</p>
        <p class="user">@{{ creator.user }}</p>
      </div>
    </div>
    <div class="subscribe-content" v-if="creator">
      <div dpadding>
        <div class="title" strong>{{ $locale["object"]["subscribe"]["subscribe-advantage-title"] }}</div>
        <div class="advantages">
          <ul>
            <li v-for="(advantage, index) in $locale['subscribe_advantages']" :key="`ad${index}`">{{ advantage }}</li>
          </ul>
        </div>
        <div v-html="normalizeText($locale['enjoy_all_creator_content'])"></div>
      </div>
      <div class="price">
        <SubscribePriceBy
          name="priceBy"
          value="monthly"
          :checked="true"
          :price="`${getPrice(subscribePrice)} <small>${$locale['usd']}</small>`"
          :label="$locale['object']['subscribe']['subscribe-month-label']"
          :subscription="creator && creator.subscriptions && creator.subscriptions.priceByMonth"
          @checked="netPrice = getPrice(subscribePrice)"
        />
      </div>
      <div class="options">
        <div dpadding>
          <LargeButton
            icon="false"
            :label="`<strong>${$locale['subscribe']}</strong>`"
            class="primary-button"
            :rightDesc="`<div><strong>${netPrice}</strong> / <small>${$locale['usd']}</small></div>`"
            @click="subscribe(creator)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../widgets/Modal.vue";
import SubscribePriceBy from "./SubscribePriceBy.vue";
import SubscribeNotFound from "./SubscribeNotFound.vue";
import SubscribeSuccess from "./SubscribeSuccess.vue";
export default {
  props: ["creator", "modal"],
  components: { Modal, SubscribePriceBy, SubscribeNotFound, SubscribeSuccess },
  data: function() {
    return {
      success_message: null,
      netPrice: null,
      settings: {
        animation: "midTopIn",
      },
    };
  },
  computed: {
    subscribePrice: function() {
      return this.creator?.subscriptions?.priceByMonth?.mount;
    },
  },
  methods: {
    getPrice: function(price) {
      const discountstatus = this.creator?.subscriptions?.priceDiscount?.status;
      const discountPercent = this.creator?.subscriptions?.priceDiscount?.percent;
      const discountDateLimit = this.creator?.subscriptions?.priceDiscount?.dateLimit;
      const isTimeLeft = this.$global.isTimeLeft(discountDateLimit);
      if (!isTimeLeft) {
        return price;
      } else if (discountstatus === "on") {
        return (price * discountPercent) / 100;
      } else {
        return price;
      }
    },
    closeSuccess: function() {
      const user = this.creator.user;
      this.$router.push("/" + user).catch(() => {});
    },
    normalizeText: function(text) {
      return text.replaceAll("$creator", `<strong>@${this.creator.user}</strong>`);
    },
    subscribe: function() {
      if (!this.$user || !this.$user.user) {
        this.$store.dispatch("payload", { after: "subscribe", creator: this.creator.user });
        this.modal.close(() => {
          this.UserAdminMenu();
        });
        return;
      }

      const priceBy = document.querySelector(`.subscribe input[name="priceBy"]:checked`)?.value;
      const _data = { creator: this.creator, priceBy, price: this.netPrice };
      this.goToPaySubscription(_data);
    },
    subscribeSuccess: function(response) {
      this.isLoading(false);
      if (response?.data?.success) {
        this.modal = 0;
        this.success_message = this.normalizeText(response?.data?.message);
        this.$store.dispatch("getProfile");
        this.ialert.close();
      } else if (response?.data?.reason === 1) {
        this.modal = 2;
        this.ialert.close();
      } else {
        this.$refs.modal.close();
        this.ialert.go({ message: this.normalizeText(response?.data?.message), unique: true, title: "Error" });
      }
    },
  },
};
</script>

<style lang="scss">
$avatar_size: 56px;
.subscribe {
  .modal-card {
    max-width: $mobile_s_width;
  }
  &-creator {
    @include Flex(inherit, center, center);
    position: relative;
    padding: $mpadding $mpadding/2 $mpadding $mpadding;
    gap: $mpadding;
    .info {
      .name {
        font-size: 110%;
        font-weight: bold;
      }
      .user {
        color: $emperor;
      }
    }
  }
  &-content {
    text-align: center;
    .price {
      margin: $mpadding 0;
      border-bottom: solid 1px $alto;
      &-by {
        text-align: left;
        border-top: solid 1px $alto;
      }
    }
    .title {
      margin: 0 0 $mpadding 0;
    }
    .advantages {
      margin: 0 0 $mpadding 0;
      font-size: 100%;
      ul {
        li::before {
          content: ".";
          margin: 0 $mpadding/2 0 0;
          position: relative;
          top: -4px;
          font-weight: 900;
          font-size: 130%;
        }
      }
    }
  }

  @include Desktop {
    &-content .advantages {
      font-size: 100%;
    }
  }
}
</style>
