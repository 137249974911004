<template>
  <a :class="`primary-button${_class ? ` ${_class}` : ''}`" @click="$emit('click')">
    <slot v-if="hasSlot"></slot>
    <span v-else v-html="label"></span>
  </a>
</template>

<script>
export default {
  props: ["label", "_class"],
  computed: {
    hasSlot: function() {
      return !!this.$slots.default;
    },
  },
};
</script>

<style lang="scss" scoped>
.primary-button {
  display: inline-block;
  background: $primary_color;
  max-width: 256px;
  padding: $mpadding/1.5 $mpadding;
  border-radius: 4pc;
  &.block {
    display: block;
  }
}
</style>
