<template>
  <div class="susbcribe-success" tcenter>
    <div class="icon"><iconic name="mf_icon_76" /></div>
    <div v-html="`${success_message}`"></div>
  </div>
</template>

<script>
export default {
  props: ["success_message"],
};
</script>

<style lang="scss" scoped>
.susbcribe-success {
  position: relative;
  text-align: center;
  .icon {
    font-size: 280%;
    color: $primary_color;
    margin: 0 0 $mpadding 0;
    .iconic {
      @include Flex(inherit, center, center);
      animation: ld-jingle 1s linear infinite;
    }
  }
}
</style>
