<template>
  <label class="price-by">
    <input type="radio" :name="name" hidden :checked="checked === true" :value="value" @change="selected" />
    <div class="price-by-content">
      <div class="price-by-check"><a class="point"></a></div>
      <div class="price-by-image" v-if="image">
        <figure class="figure"><img alt="Image" :src="image" /></figure>
      </div>
      <div>
        <p class="price-by-label" v-html="label" strong></p>
        <p class="price-by-mount" v-html="price" strong></p>
      </div>
    </div>
  </label>
</template>

<script>
export default {
  props: ["price", "label", "checked", "name", "value", "image"],
  methods: {
    selected: function({ target: { checked } }) {
      if (checked) this.$emit("checked");
    },
  },
  mounted: function() {
    if (this.checked) this.$emit("checked");
  },
};
</script>

<style lang="scss" scoped>
.price-by {
  @include Flex(inherit, inherit, center);
  cursor: pointer;
  &-content {
    @include Flex(inherit, inherit, center);
    padding: $mpadding/2;
    border-color: $alto;
    width: 100%;
    gap: $mpadding/2;
  }
  &-label {
    font-size: 110%;
    color: $primary_color;
    font-weight: bold;
  }
  &-mount {
    color: $emperor;
    font-size: 90%;
  }
  &-check {
    @include Flex(inherit, center, center);
    padding: $mpadding/2 $mpadding/2 $mpadding/2 $mpadding/2;
    .point {
      @include Flex(inherit, center, center);
      width: $mpadding * 1.5;
      height: $mpadding * 1.5;
      min-width: $mpadding * 1.5;
      min-height: $mpadding * 1.5;
      border: solid 2px $emperor;
      border-radius: 50%;
      &:before {
        content: "";
        width: $mpadding/1.2;
        height: $mpadding/1.2;
        display: inline-block;
        border-radius: 50%;
      }
    }
  }
  input:checked ~ &-content {
    background-color: rgba(234, 0, 0, 0.05);
  }
  input:checked ~ &-content &-mount {
    font-weight: bold;
  }
  input:checked ~ &-content &-check {
    .point {
      &:before {
        background-color: $primary_color;
      }
    }
  }
  &-image {
    width: $mpadding * 3;
    margin: 0 $mpadding/2 0 0;
    .figure {
      @include Flex(inherit, center, center);
      overflow: hidden;
      img,
      svg {
        max-width: 100%;
      }
    }
  }
}
</style>
